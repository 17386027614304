import { Search } from '@element-plus/icons';
import { Loader } from '@googlemaps/js-api-loader';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import BackButton from '@/core/components/ui/BackButton.vue';
import { PAGE_LIMIT } from '@/core/constants';
import { PROPERTY_STORE } from '@/store/modules/property-details';
export default defineComponent({
    components: {
        Search,
        BackButton
    },
    data() {
        return {
            address: '',
            selectedAddress: '',
            fullAddress: '',
            foundAddress: false,
            createSubmitting: false,
            google: null,
            map: null,
            currentFilters: {
                page: 1,
                limit: PAGE_LIMIT,
                search: '',
            },
            windowWidth: window.innerWidth,
            streetViewControl: true,
            mapTypeControl: true,
            fullscreenControl: true,
            zoomControl: true
        };
    },
    watch: {
        windowWidth(newWidth) {
            this.windowWidth = newWidth;
            this.setMapControls();
        },
        selectedAddress: {
            handler(newData) {
                if (newData !== '') {
                    this.foundAddress = true;
                }
                else {
                    this.foundAddress = false;
                }
            }
        },
    },
    created() {
    },
    computed: {
        propertyCoordinates() {
            const coordinates = { lat: -33.8688, lng: 151.2195 };
            return coordinates;
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        this.setMapControls();
        this.loadMap();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
        // window.removeEventListener('places_changed');
    },
    methods: {
        ...mapActions(PROPERTY_STORE, ['createProperties']),
        loadMap() {
            const loader = new Loader({
                // apiKey: process.env.VUE_APP_GOOGLE_MAP_API,
                // AIzaSyDC60GogIDU3KuA26p_KCHzj9QbUzA-0H4
                apiKey: 'AIzaSyBqDGZokEChePzwZkDaWa_POVHt0AgaMfg',
                version: 'weekly',
                libraries: ['places']
            });
            loader.load().then((google) => {
                this.google = google;
                this.initiateMap();
            });
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        setMapControls() {
            if (this.windowWidth < 768) {
                this.streetViewControl = false;
                this.mapTypeControl = false;
                this.fullscreenControl = false;
                this.zoomControl = false;
            }
            else {
                this.streetViewControl = true;
                this.mapTypeControl = true;
                this.fullscreenControl = true;
                this.zoomControl = true;
            }
            this.loadMap();
        },
        initiateMap() {
            const { streetViewControl, mapTypeControl, fullscreenControl, zoomControl } = this;
            const map = new this.google.maps.Map(document.getElementById('map'), {
                center: { lat: -33.8688, lng: 151.2195 },
                zoom: 13,
                mapTypeId: 'roadmap',
                streetViewControl,
                mapTypeControl,
                fullscreenControl,
                zoomControl
            });
            // Create the search box and link it to the UI element.
            const input = document.getElementById('pac-input');
            const searchBox = new this.google.maps.places.SearchBox(input);
            // const searchBox = new this.google.maps.places.Autocomplete(input);
            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', () => {
                // searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
                searchBox.setBounds(map.getBounds());
            });
            let markers = [];
            // let markers: google.maps.Marker[] = [];
            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces();
                if (places.length === 0) {
                    return;
                }
                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });
                markers = [];
                // For each place, get the icon, name and location.
                const bounds = new this.google.maps.LatLngBounds();
                places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        return;
                    }
                    const icon = {
                        url: place.icon,
                        size: new this.google.maps.Size(71, 71),
                        origin: new this.google.maps.Point(0, 0),
                        anchor: new this.google.maps.Point(17, 34),
                        scaledSize: new this.google.maps.Size(25, 25),
                    };
                    // Create a marker for each place.
                    markers.push(new this.google.maps.Marker({
                        map,
                        icon,
                        title: place.name,
                        position: place.geometry.location,
                    }));
                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    }
                    else {
                        bounds.extend(place.geometry.location);
                    }
                    // console.log('lat', place.geometry.location.lat());
                    this.selectedAddress = place.formatted_address;
                    this.fullAddress = {
                        ADDRESS: this.selectedAddress,
                        latlng: [place.geometry.location.lat(), place.geometry.location.lng()]
                    };
                });
                map.fitBounds(bounds);
                // console.log('bounds', bounds);
            });
        },
        async createAndRouteToBilling() {
            this.createSubmitting = true;
            let property;
            try {
                property = (await this.createProperties({
                    address: this.selectedAddress,
                    postcode: 'N/A',
                    addressData: JSON.stringify(this.fullAddress) || '{}',
                    epcData: '{}'
                }));
                this.createSubmitting = false;
                // console.log(property.id);
                this.$router.push({ name: 'billing', params: { id: property.id } });
            }
            catch (e) {
                // console.log(e);
                this.createSubmitting = false;
            }
        },
    }
});
